.home .showNav {
    top: 0;
    transition: all 0.7s ease-in-out;
}

.hamburger-nav {
    display: none;
}
#hamburger-nav {
    .fixed-nav_container {
        .fixed-nav_links {
            a span br {
                display: none;
            }
        }
        .nav-language-container {
            width: 85%;
            padding-top: 24px;
            border-top: 1px solid hsla(0, 0%, 100%, .5);
            margin: 20px auto;

            .lanSelect {
                display: block;
                padding: 0;

                .lang-button {
                    cursor: pointer;
                    list-style: none;                 
                    padding: 12px 0 12px 16px;             
                    font-size: 14px;         
                    border: 0;          
                    display: block;           
                    background-color: transparent;            
                    color: white;

                    &:hover {
                        font-weight: 700;
                    }
                }
                .lang-button-active {
                    cursor: pointer;
                    list-style: none;                 
                    padding: 12px 0 12px 16px;             
                    font-size: 14px;         
                    border: 0;          
                    display: block;           
                    background-color: transparent;            
                    color: white;
                    font-weight: 700;
                }
            }
            .lanSelect-show {
                display: block;
            }
        }
    }
}
.fixed-nav {
    position: fixed;
    display: none;
    top: -100%;
    left: 0;
    color: #000;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    transition: all 0.7s ease-in-out;

    
    .fixed-nav_container {
        max-width: 1200px;
        width: 95%;
        margin: 0 auto;
        z-index: 20;

        .fixed-nav_logo {
            padding: 0;
            img {
                width: 160px;
                margin-top: 12px;
                height: auto;
            }
        }
        .hamburger {
            display: none;
        }
    
        .fixed-nav_links {
            display: inline-block;
            float: right;
            margin: 0;

            a {
                display: inline-block;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.5px;
                padding: 25px 0;
                margin-right: 30px;
                // text-transform: capitalize;
                cursor: pointer;
                border-bottom: 4px solid #0c2c40;
                transition: all 0.2s ease-in-out;
                text-decoration: none;
                color: #000;
    
                &:nth-last-of-type(1) {
                    margin: 0;
                }
    
                &.active, &:hover {
                    border-bottom: 4px solid #69b3e7;
                }
                span {
                    br {
                        display: none;
                    }
                }
            }
        }
        .nav-language-container {
            display: none;
        }
    }
    .fixed-member_container {
        display: none;
    }
}

.home-nav {
    padding: 15px 0 0px 0;
    margin-bottom: 100px;

    .home-nav-container {
        max-width: 1100px;
        width: 95%;
        margin: 0 auto;
        position: relative;

        img {
            float: left;
            max-width: 160px;
            height: auto;

        }
        .hamburger {
            display: none;
            position: absolute;
            width: 22px;
            padding: 2px;
            top: 0px;
            right: 0px;
            cursor: pointer;

            img {
                width: 100%;
            }
            // right: 4px;
        }
        .nav-language-container {
            float: right;
            // display: none;
            position: relative;
            padding-top: 12px;

            .nav-language-menu {
                font-size: 16px;
                font-weight: 300;
                display: inline-block;
                color: #000;
                cursor: pointer;
                // margin-top: 25px;
                // positon: relative;

                span {
                    padding-right: 6px;
                }

    

                
         
                

                .flip-horizontal-top {
                    position: absolute;
                    // display: inline-block;
                    width: 12px;
                    top: 22px;
                    // right: -22px;
                    animation: 0.4s ease-in-out;
                   
                        // -webkit-animation: flip-horizontal-top 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
                        //         animation: flip-horizontal-top 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
                    
                    // padding: 24px 18px;
                }
            }
            .flip-horizontal-bottom {
                -webkit-animation: flip-horizontal-bottom 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
                        animation: flip-horizontal-bottom 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
                        position: absolute;
                    width: 12px;
                    top: 22px;
                    // right: -22px;
            }


@-webkit-keyframes flip-horizontal-top {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(180deg);
              transform: rotateX(180deg);
    }
  }
  @keyframes flip-horizontal-top {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(180deg);
              transform: rotateX(180deg);
    }
  }
  


@-webkit-keyframes flip-horizontal-bottom {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(-180deg);
              transform: rotateX(-180deg);
    }
  }
  @keyframes flip-horizontal-bottom {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(-180deg);
              transform: rotateX(-180deg);
    }
  }
  
            select {
                float: right;
                // display: none;
            }
            .lanSelect {
                display: none;
                position: absolute;
                padding: 10px 0;
                z-index: 30;
                .lang-button {
                    padding: 7px 70px 7px 19px;
                    width: 140px;
                    // padding: 0;
                    border: 0;
                    background-color: #fff;
                    color: black;
                    list-style: none;
                    font-size: 14px;
                    cursor: pointer;

                    &:hover, &:focus{
                        background-color: #0c2c40;
                        color: #fff;
                        transition: ease-in-out 0.1s;
                    }

                }
                .lang-button-active {
                    padding: 7px 70px 7px 19px;
                    width: 140px;
                    // padding: 0;
                    border: 0;
                    list-style: none;
                    font-size: 14px;
                    cursor: pointer;
                    background-color: #0c2c40;
                    color: #fff;
                    transition: ease-in-out 0.1s;
                }
                
            }
            .lanSelect-show {
                display: block;
            }
        } 
    }
}

.scroll {
    background: linear-gradient(180deg, rgba(179, 188, 200, .33), #fff);
}

.home {
    &::after{
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        z-index: -1;
        background: linear-gradient(0deg, rgba(0,0,0,0) 65%, white 90%);
        // display:inline-block;
        // background: -moz-linear-gradient(0deg, rgba(0,0,0,0) 65%, white 90%); /* FF3.6+ */
        // background: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 65%, white 90%); /* Chrome10+,Safari5.1+ */
        // background: -o-linear-gradient(0deg, rgba(0,0,0,0) 65%, white 90%); /* Opera 11.10+ */
        // background: -ms-linear-gradient(0deg, rgba(0,0,0,0) 65%, white 90%); /* IE10+ */
        
    }
    .scroll-nav {
        display: none;
        .scroll-item .active {

        }
    }
}
.scroll .scroll-nav {
    display: none;
}
.scroll-nav {
    display: block;
    text-align: center;
    position: relative;

    .scroll-item {
        transform: translateY(-80px);
        background: white;
        display: inline-block;
        text-decoration: none;
        // margin: 0 20px;
        margin: 0 0.7%;
        width: 190px;
        height: 180px;
        border-radius: 5px;
        border: 1px solid #D4D4D0;
        vertical-align: bottom;
        // box-shadow: 0 10px 17px -9px hsla(0, 0%, 44%, .2);
        transition: all 500ms cubic-bezier(.645, .045, .355, 1);
        cursor: pointer;
        opacity: 0.74;
        transform: translate(0px, 250px);


        &.active {
            opacity: 1;
            transform: translateX(0px) translateY(-80px) translateZ(0px);
            transition: opacity 200ms ease 0s, transform 700ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
        }
        img {
            display: block;
            // width: 45px;
            // width: 25%;
            // margin: 45px auto 20px auto;
            height: 47px;
            width: 47px;

            margin: 20% auto 10% auto;
        }

        span {
            font-size: 16px;
            color: rgb(124, 132, 138);
            text-decoration: none;
            width: 20px;
            text-transform: uppercase;
            letter-spacing: 1.25px;
            transition: all 0.5s ease-in-out;
            // transition: opacity 200ms ease-in-out 0s, transform 700ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
        }
        &:first-of-type {
            &.delay {
                transition: opacity 200ms ease 0s, transform 700ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
            }
        }
        &:nth-of-type(2) {
            &.delay  {
                transition: opacity 200ms ease 0s, transform 900ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
            }
        }
        &:nth-of-type(3) {
            &.delay  {
                transition: opacity 200ms ease 0s, transform 1100ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
            }
        }
        &:nth-of-type(4) {
            &.delay {
                transition: opacity 200ms ease 0s, transform 1300ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
            }
        }
        &:nth-of-type(5) {
            &.delay  {
                transition: opacity 200ms ease 0s, transform 1500ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
            }
        }
        &:first-of-type, &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5){
            &.active {
                &:hover {
                    box-shadow: 0 18px 17px -9px hsla(0, 0%, 44%, .2);
                    transform: translate(0px, -89px);
                    transition: all 500ms ease;

                    span {
                        color: #006080;
                    }
                }
            }
        }
    }
}