@media (max-width: 1400px){
    .main-item{
        .main-item_content{
            .main-item_buttons{
                .cta-button{
                    .tool-tip{
        
                        .transfer-cta-button-tool-tip-content, .request-transfer-cta-button-tool-tip-content{
                            left: auto;
                            right: 5px;
                            top: 14px;
                            transform: translateY(-60px);
                        }
                     }
                }
            }
        }
    }
    
    
}

@media (max-width: 1200px){
    .home-nav{
        margin: 0px 20px 100px 35px;
    }
    
    .home-content{
        width: auto;
        margin-left: 60px;
        h1{
            width: 500px;
            
        }
        p{
            width: 450px;
        }
    }
    .main-item .main-item_image {
        margin: 0px 10px;
    }
    .main-item.transfer .main-item_content {
        margin-left: 8%;
    }
    
}

@media (max-width: 1140px) {
    .main-item .main-item_image {
        margin: 0px 10px 0 0px;
    }
    .main-item.transfer .main-item_content {
        margin-left: 11vh;
    }
}
//MOBILE TABLET LARGE WIDTH - 1024px

@media (max-width: $screen-tablet-lrg) {

    // Transparent Black Overlay for Hamburger Menu
    .home .black-show {
        display: block;
        opacity: 0.8;
        transition: all 0.2s ease-in-out;
    }

    // Show Hamburger Menu
    .home .hamburger-show {
        right: 0;
        transition: all 0.2s ease-in-out;
    }
.home-nav{
    margin: 0px 20px 100px 0px;
}
    // Hide Language Selector when Hamburger Appears
    .home .home-nav .home-nav-container .nav-language-container {
        display: none;
    }
    .home {
        // background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 25%, white 90%), url(/static/media/GettyImages-1158754142.101427d1.jpg);
        .home-content
        {
            max-width: 500px;
            text-align: center;
            margin: 0px auto 200px;
            h1{
                width: 100%;
                font-size: 23px;
                margin-bottom: 10px;
            }
            p{
                width: 100%;
            }
        }
    }
    // HAMBURGER NAV
    .hamburger-nav {
        display: block;
        position: fixed;
        top: 0;
        right: -340px;
        left: unset;
        width: 340px;
        height: 100vh;
        z-index: 900;
        background-color: #0C2340;
        color: #fff;
        transition: all 0.2s ease-in-out;

        .hamburger-member_container {
            width: 100%;
            margin: 0 auto;
            padding: 28px 0 40px 0;
            display: block;
            background: linear-gradient(180deg, #01246b, #01246b);

            .member-greeting {
                border-bottom: 1px solid hsla(0, 0%, 100%, .35);
                margin: 0 auto 18px auto;
                width: 85%;
                p {
                    display: inline-block;
                    margin: 0;
                    padding-bottom: 8px;
                }
                span {
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                }
            }
            .member-balance {
                margin: 0 auto 18px auto;
                width: 85%;
                p {
                    display: inline-block;
                    color: #ffbd00;
                    margin: 0;
                }
                span {
                    display: inline-block;
                    margin: 0;
                }
            }
            .member-number {
                width: 85%;
                margin: 0 auto;
                p {
                    display: inline-block;
                    margin: 0;
                }
                span {
                    display: inline-block;
                    margin: 0 0 4px 0;
                }
            }
        }
        .fixed-nav_container {

            .fixed-nav_logo {
                display: none;
                padding: 12px 12px;
                img {

                    width: 120px;
                    margin: 12px 12px;
                }
            }

            .fixed-nav_links {
                float: none;
                width: 85%;
                margin: 0 auto;
                cursor: pointer;

                a {
                    display: block;
                    float: none;
                    text-align: left;
                    padding: 16px 0;
                    width: 85%;
                    color: #fff;
                    text-decoration: none;

                    &:nth-last-of-type(1) {
                        width: 85%;
                    }

                    span {
                        // text-transform: capitalize;
                        text-align: left;
                        font-size: 13px;
                        font-weight: 400;
                        letter-spacing: 2px;
                        line-height: 16px;
                    }
                }
                .fixed-nav_member {
                    display: block;
                    background: linear-gradient(180deg, #01246b, #01246b);

                }
                .fixedNavItems {
                    a {
                        display: block;
                        float: none;
                        text-align: left;
                        padding: 20px 0 0 20px;

                        span {
                            // text-transform: capitalize;
                            text-align: left;
                            font-size: 13px;
                            font-weight: 400;
                            letter-spacing: 2px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }

    // FIXED NAV
    .fixed-nav {
        position: fixed;
        display: none;
        top: -100%;
        left: 0;
        color: #fff;
        width: 100%;
        z-index: 100;
        transition: all 0.4s ease-in-out;


        .fixed-nav_container {
            max-width: 1200px;
            margin: 22px auto;
            z-index: 20;
            position: relative;


            .fixed-nav_logo {

                img {
                    width: 120px;
                    margin: 14px 12px 8px 12px;

                }
            }
            .hamburger {
                display: inline-block;
                position: absolute;
                width: 25px;
                padding: 2px;
                top: 17px;
                right: 10px;

                img {
                    width: 100%;
                    margin-top: 12px;
                }
            }

            .fixed-nav_links {
                display: none;
                float: right;
                margin: 0;

                a {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 0.5px;
                    padding: 25px 0;
                    margin-right: 30px;
                    // text-transform: capitalize;
                    cursor: pointer;
                    border-bottom: 3px solid #039;
                    transition: all 0.2s ease-in-out;

                    &:nth-last-of-type(1) {
                        margin: 0;
                    }

                    &:hover {
                        border-bottom: 3px solid #ffbd00;
                    }
                }
            }
            .nav-language-container {
                display: none;
            }
        }
    }

    .home-nav .home-nav-container .hamburger {
        display: block;
    }

    .scroll {
        .scroll-nav {
            .scroll-item {
                width: 134px;
                height: 137px;


                img {
                    width: 35px;
                    height: 25px;
                }

                span {
                    font-size: 13px;


                }
            }
        }
    }

    // .main-item:nth-child(odd) {


    //     .main-item_content {
    //         margin-right: 40px;
    //     }
    //     .main-item_image {

    //     }
    // }
    // .main-item:nth-child(even) {

    //     .main-item_image {
    //         margin-right: 40px;
    //     }
    // }

    .main-item {
        margin: 0 auto 0px auto;
        width: 100%;
        &.transfer{
            .main-item_content{
                margin-left: 0%;
                padding-left: 7%;
            }
        }
        .main-item_image {
            display: inline-block;
            height: 450px;
            width: 50%;
            margin: 0px 10px;

            .main-item_quote {
                display: none;
            }
        }
        .main-item_content {
            display: inline-block;
            width: calc(60% - 40px);
            margin: 0;

            h2 {
                font-size: 26px;
            }
            h3 {
                font-size: 16px;
            }
            h4 {
                margin-bottom: 25px;
            }
            .marriott-content {
                margin-top: 15px;
            }

            .main-item_buttons {
                .cta-button{
                    .left-block{
                        width: 14%;
                        text-align: center;
                    }
                }
                a {
                    font-size: 15px;
                }
            }
        }
    }
}

//MOBILE LARGE WIDTH - 767px
@media (max-width: $screen-mobile-lrg) {

    .home .home-banner {

    }

    .home .home-bg {
        background: initial;
    }
    // Hamburger Nav Width Adjust
    .home .hamburger-nav {
        right: -240px;
        width: 240px;
    }
    // Show Hamburger Menu
    .home .hamburger-show {
        right: 0;
        transition: all 0.2s ease-in-out;
    }

    // Fixed Nav Height Adjust
    .home .fixed-nav .fixed-nav_container {
        margin: 0 auto;
    }


    // // SCROLL NAV

    .home {
        // background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, white 90%), url(/static/media/GettyImages-1158754142.101427d1.jpg) !important;
        // background-position: center;
    //     height: 32vw;
    // max-height: 600px;
    min-height: 460px;
        .scroll_mobile {
            display: none;
            .scroll-nav {
                display: block;

                h1 {
                    margin-bottom: 0;
                    width: auto;
                }

                .scroll-item  {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 72%;
                    height: 55px;
                    margin: 20px auto;
                    // background: linear-gradient(357deg, #c7c7c7, #fff);
                    background-color: #fff;
                    color: #fff;
                    position: relative;
                    transform: translateX(0px) translateY(0px) translateZ(0px);
                    transform: scale(0.5, 0.5);
                    opacity: 0;


                    &.active {
                        opacity: 1;
                        transform: scaleX(1) scaleY(1) scaleZ(1);
                        transition: 500ms all ease-in;
                    }
                    // &:first-of-type {
                    //     &.active {
                    //         transition: 500ms all ease-in;
                    //     }
                    // }
                    // &:nth-of-type(2) {
                    //     &.active {
                    //         transition: 700ms all ease-in;
                    //     }
                    // }
                    // &:nth-of-type(3) {
                    //     &.active {
                    //         transition: 900ms all ease-in;
                    //     }
                    // }
                    // &:nth-of-type(4) {
                    //     &.active {
                    //         transition: 1100ms all ease-in;
                    //     }
                    // }
                    // &:nth-of-type(5) {
                    //     &.active {
                    //         transition: 1300ms all ease-in;
                    //     }
                    // }
                    img {
                        display: inline-block;
                        width: 25px;
                        height: auto;
                        margin: 0 auto;
                        position: absolute;
                        left: 14px;
                        top: 14px;


                    }
                    span {
                        display: inline-block;
                        font-weight: 400;
                        color: #006080;
                        width: 70%;
                        text-align: left;
                        vertical-align: middle;
                        // margin: 18px 0px 0px 34px;
                        margin-left: 35px;
                        font-size: 14px;
                        letter-spacing: 0;

                        br {
                            display: none;
                        }
                    }
                }
            }
        }
        
    }

    // SCROLL

    .scroll {
        .scroll-nav {
            display: none;
        }
    }


    // HOME NAV

    .home-nav {
        margin-bottom: 15px;
        padding: 40px 0 50px;


        .home-nav-container {
            width: 90%;

            a {
                img {
                    width: 120px;

                }
            }
            .hamburger {
                display: inline-block;

                img {
                    width: 25px;
                    margin-top: 12px;
                }
            }
        }
    }

    // HOME
.home{
    .home-content {
        margin: 0px auto 100px auto;
        p{
            font-size: 16px;
        }
        h1 {
            font-size: 21px;
            line-height: 1.3;
            font-weight: 600;

        }

        h2 {
            margin-bottom: 40px;
        }
        .home-subheader_desktop {
            display: none;
            h2 {
                font-size: 16px;
                line-height: 24px;

            }
        }
        .home-content_text, .home-subheader_mobile {
            display: block;
            text-decoration: none;
            transform: scale(0.5, 0.5);
            opacity: 0;

            span {

                color: #fff;
                line-height: 24px;
                font-size: 16px;
                text-decoration: none;

            }
            a {
                border-bottom: 1px solid #fff;
                line-height: 12px;
            }
            &.active {
                opacity: 1;
                transform: scaleX(1) scaleY(1) scaleZ(1);
                transition: 300ms all ease-in;
            }
        }
    }
}
    // More Ways To Earn

    .earn {
        h2 {
            width: 95%;
        }
        .earn-container a {
            width: 95%;
        }
    }



    // FOOTER
.footer-block{
    .home-content_text{
    margin-left: 30px;
}
}
    .footer-container {
        width: 100%;
        flex-direction: column;
      justify-content:flex-start;
        .footer-item {
            display: block;
            width: 90%;
            padding: 14px 0;
            margin: 0 auto 10px auto;
            border-bottom: 1px solid #c6c6c6;;

            &:nth-last-of-type(1) {
                padding: 14px 0 0 0;
                margin: 0 auto;
                border-bottom: none;
            }
        }
    }

    // MAIN

    .main {
        .main-item {
            margin: 40px 0 30px 0;
            padding: 0;
            width: 100%;
            display: block;
           
            &.transfer{
                .main-item_content{
                    padding-left: 0%;
                }
                .main-item_image {
                    display: block !important;
                }
            }
            .main-item_image {
                display: none;
                float: none;
                width: auto;
                border-radius: 0%;
                max-width: 100%;
                margin: 0px;
                height: 450px;
                position: relative;
                background-size: 100%;
                background-position: center 85%;

                .main-item_quote {
                    display: none;
                }
                .main-item_icon {
                    display: none;
                    background: #fff;
                    border-radius: 50%;
                    position: absolute;
                    bottom: -42px;
                    left: 0px;
                    img {
                        width: 40px;
                        margin: 23px;
                    }
                }
            }
            .main-item_content {
                display: block;
                width: auto;
                margin: 4% 10% 0% 10%;

                h3 {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                h2 {
                    font-size: 24px;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
                h4 {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
                .main-item_buttons {
                    .cta-button{
                        
                        .tool-tip{
        
                            .tool-tip-content{
                            left: auto;
                            right: 5px;
                            top: 14px;
                            transform: translateY(-60px);
                            }
                         }
                    }

                    // a {
                    //     // background-color: #039;
                    //     color: #fff;
                    //     padding: 20px 0;
                    //     font-size: 18px;

                    //     img:nth-of-type(1) {
                    //         width: 0;
                    //     }
                    //     span {
                    //         left: 14px;
                    //         width: 100%;

                    //         .sale-text {
                    //           left: 104%;
                    //           right: -100%;
                    //         }
                    //     }
                    //     &.expanded {

                    //         img {
                    //             display: none;
                    //         }
                    //         span {
                    //             margin-left: 14px;
                    //         }

                    //     }
                    //     &.disabled {
                    //         background-color: #fff;

                    //         &>span {
                    //             top: 12px;
                    //             .transfer-insuff-msg {
                    //                 left: 0;
                    //                 font-size: 11px;
                    //                 width: 100%;

                    //             }
                    //         }
                    //     }
                    // }
                }
            }
        }
        .pointshound .main-item_image {
            // background-position: 31% 0%;
        }
        
    }
}

//MOBILE SMALL WIDTH

@media(max-width: $screen-mobile-sml){
    
    #multiply-cta-button, #subscribe-cta-button {
        img.left-mobile-block{
            display: none;
        }
    }
    #buy-cta-button, #gift-cta-button, #transfer-cta-button, #request-transfer-cta-button{
        div.left-mobile-block{
            display: none;
        }
    }
    .home{
        background-size: cover;
background-position: 50% 70%;
        .location-label{
            display: none;
        }
        .home-nav{
            padding: 20px 0 50px;
        }
        .home-content{
            margin: 0px 20px auto;
            h1{
                margin-bottom: 10px;
                font-size: 17px;
                text-shadow: none;
            }
            p{
                font-size: 16px;
                text-shadow: none;
            }
            .home-mobile-content_text{
                max-width: 550px;
                transform: none;
                color: #000;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
               
                p{
                    width: auto;
                    line-height: 1.5;
                    margin-right: 5px;
                    span{
                        color: #000;
                        text-shadow: 2px 2px 6px #fff;
                    }
                    
                }
                a{
                    border-bottom: none;
                    text-decoration: none;
                    line-height: 1.5;
                    text-shadow: 2px 2px 6px #fff;
                    span{
                       
                    color: #000;
                    font-weight: 600;
                    }
                }
            }
        }
    }
    .App {
        .main {
            .main-item {

                &.buy{
                     .main-item_image {
                            display: none;
                        }
                    }
                .main-item_image {
                    background-size: cover;
                    height: 300px;
                    width: 95%;
                    margin: 0 auto;
                   
                    // display: none;
                }
                .main-item_buttons{
                    .cta-button{
                        .left-block{
                            display: none;
                        }
                        a{
                            text-align: left;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            width: 100%;
                            max-width: 440px;
                            span{
                                width: 70%;
                                font-size: 13px;
                                margin: 0px ;
                                text-align: center;
                            }
                            img.left-mobile-block{
                                width: 10%;
                                margin-left: 20px;
                                height: 24px;
                                
                            }
                            .left-mobile-block{
                                display: none;
                                // margin-right: 20px;
                            }
                            .right-mobile-block{
                                display: none;
                                margin-right: 20px;
                            }
                            
                            div.left-mobile-block{
                                margin-left: 20px;
                                font-size: 15px;
                                // background-color: red;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-block{
        .home-content_text{
        margin-left: 30px;
        display: none;
    }
    }
}
@media (max-width: 390px){
    // .main-item.buy .main-item_content .main-item_buttons .cta-button a {
    //     width: 100%;
    //     max-width: 265px;
    // }
    .main .main-item .main-item_content{
        margin: 4% 5% 0% 5%;
    }
    .main-item .main-item_content .main-item_buttons .cta-button .right-block .right-copy{
        font-size: 11px;
    }
}
@media (max-width: 420px){
    .home{
        min-height: 420px;

        .home-content{
            h1{
                font-size: 16px;
                text-shadow: none;
            }
            p{
                font-size: 12px;
            }
            .home-mobile-content_text{
                font-size: 12px;
                display: block;
             }
        }
        

    }
 
}

@media(max-width: 950px){
     #transfer-cta-button-right-icon, #request-transfer-cta-button-right-icon {
        width: 25%;
    }
  }

  @media only screen and (max-width: 770px) and (min-width: 768px){
    #request-transfer-cta-button{
        padding: none;
    }
 }