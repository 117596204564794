.footer {

    padding: 40px 0;
    border-top: 2px none #039;
    clear: both;
    .home-content_text {
        line-height: 30px;
        width: 95%;
    max-width: 1150px;
    margin: auto;
        p {
            display: inline-block;
            font-size: 18px;
            font-weight: 400;
            
        }

        a  {
            display: inline-block;
            margin-left: 4px;
            text-decoration: underline;
            // text-decoration: none;
            color: #006080;
            border-bottom: 1px solid transparent;
            transition: all 0.2s ease-in-out;
            &::after{
                content: ">";
                margin-left: 5px;
                text-decoration: none;
            }
            &:hover {
                color: #0033a0;
                border-bottom: 1px solid #fff;
            }
        }
    } 
}

.footer-container {
    width: 95%;
    max-width: 1150px;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding: 20px 0;
    .footer-item {
        display: inline-block;
        text-align: left;
        width: 23%;
        margin: 0 2% 0 auto;
        vertical-align: text-top;

        h4 {
            font-size: 16px;
            font-weight: 600;
            color: #000;
            margin: 10px 0;
        }
        a {
            display: block;
            text-decoration: none;
            color: #000;
            vertical-align: text-top;
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 8px;
            cursor: pointer;
            
            span {
                border-bottom: 1px solid transparent;
                transition: all 0.2s ease-in-out;

                &:hover {
                    border-bottom: 1px solid #000;
                }
            }
        }
    }
}