.main-item_quote .slideUp {
    display: none;
    // background-color: blue;
}

.buy {
    margin: 0 auto 70px auto;

    .main-item_image {
        background-image: url(../assets/photography/desk-5.jpg);
        // background-position: 18% 98%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
}

.transfer .main-item_image {
    background-image: url(../assets/photography/desk-7.jpg);
    // background-position: 40% 70%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.reinstate .main-item_image {
    background-image: url(../assets/photography/desk-9.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 470px;
}
.pointshound .main-item_image {
    background-image: url(../assets/photography/pointshound_miles.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 480px;
}
.main {
    background-color: #fff;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.main-item {
    max-width: 1150px;
    width: 100%;
    margin: 0 auto 170px auto;
    // padding: 125px 0;
    clear: both;
    padding-top: 40px;
    margin-bottom: 50px;
    position: relative;
    background-color: #fff;
    display: flex;
    
&.buy{
    flex-direction: row-reverse;
    .main-item_content {
               
        margin-left: 2%;
        margin-right: 25px;

        .main-item_buttons {
            .cta-button{
             a{
                width: 100%;
                max-width: 285px;
                 span{
                        
                        margin: 0;// 0 20px;
                        @media(max-width: 780px){
                            margin: 0 auto;
                        }
                        @media(max-width: 560px) {
                            width: 100% !important;
                            margin: 10px !important;
                        }
                 }
             }
            }
         }
    }
   
}
&.transfer{
    flex-direction: row;
    .main-item_content {
               
                margin-left: 10%;
                .main-item_buttons {
                    .cta-button{
                     a{
                        width: 100%;
                        max-width: 325px;
                         span{
                            
                                margin: 0 15px;
                                @media(max-width: 950px){
                                    margin: 0 auto;
                                }
                                @media(max-width: 860px) {
                                    font-size: 12px;
                                }
                                @media(max-width: 767px) {
                                    font-size: 14px;
                                }
                                @media(max-width: 560px) {
                                    width: 100% !important;
                                    margin: 10px !important;
                                }
                         }
                     }
                    }
                 }
            }
}

    .main-item_image {
        width: 50%;
    max-width: 515px;
    height: 485px;
    position: relative;
    border-radius: 6px;


        .main-item_quote { 
            background-color: #fff;
            border: 1px solid black;
            // height: 150px;
            width: 220px;
            padding: 8px 15px;
            font-size: 14px;
            text-align: center;
            border: 0px solid #cecece;
            border-radius: 3px;
            box-shadow: 0 0 6px 0 #ccc;
            position: absolute;
            transition: all 250ms;

            p:nth-of-type(2) {
                font-style: italic;
                font-weight: 300;
            }
        }
        .main-item_icon {
            display: none;
        }
    }
    .main-item_content {
        // float: left;
        width: 50%;

        .main-item_buttons {

            .cta-button{
                display: flex;
                flex-wrap: nowrap;
                width: auto;
                align-items: center;
                margin-bottom: 16px;
                &:nth-child(even) {
                    a {
                        color: #745AC4;
                        background-color: #FFFFFF;
                        border: 2px solid #6244BB;
                        box-shadow: none; //0 1px 1px 0 rgb(147, 147, 147);

                        &:hover {
                            border-style: solid;
                            color: #745AC4;
                            background-color: #F7F7F7;
                            border-color: #6244BB;
                            box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5); //0 16px 16px -9px hsla(0, 0%, 44%, .2);
                        }
                    }
                }

                .left-block{
                    width: 10%;
                    text-transform: uppercase;
                    color: #6244BB;
                    font-size: 13px;
                }
                 .right-block {
                    width: 25%;
                    text-transform: uppercase;
                    color: #6244BB;
                    
                    .right-copy{
                        font-size: 13px;
                        font-weight: bold;
                    }
                    
                 }
                 .tool-tip{
                    
                    height: 22px;
                    width: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: #006080 2px solid;
                    border-radius: 50%;
                    // border-bottom-left-radius:0px;
                    margin: 0px 15px auto 4px;
                    position: relative;
                    cursor: pointer;
                    @media (max-width: 840px){
                        width: 28px;
                    }
                    @media (max-width: 768px){
                       
                        width: 25px;
                    }
                    @media (max-width: 560px){
                       
                        width: 29px;
                    }
                    .tool-tip-logo{
                        font-size: 12px;
                        color: #000;
                        font-weight: 500;
                    }
                    .tool-tip-content{
                        border: #ccc 1px solid;
                        text-align: center;
                        font-size: 10px;
                        width: 250px;
                        border-radius: 5%;
                        position: absolute;
                        top: 0%;
                        left:20px;
                        visibility: hidden;
                        z-index: 1;
                        background-color: #fff;
                    }
                    &:hover .tool-tip-content{
                        visibility: visible;
                        
                    }
                 }
                a {
                    // width: 65%;
                    position: relative;
                    text-align: center;
                    align-items: center;
                    display: block;
                    font-size: 14px;
                    color: #FFF;
                    background-color: #6244BB;
                    border: 2px solid #745AC4;
                    font-weight: 600px;
                    box-shadow: -1px 1px 1px 0 #666;
                    transition: box-shadow 342ms ease, all 500ms ease;
                    border-radius: 4px;
                    height: 52px;
                    cursor: pointer;
                    .left-mobile-block{
                        display: none;
                    }
                    .right-mobile-block{
                        display: none;
                    }
                    span {
                        // position: absolute;
                        // left: 75px;
                        // top: 35%;
    
                        .sale-text {
                          left: initial;
                          top: 4%;
                          right: -50%;
                          color: #ffbd00;
                        }
                    }
    
    
                    // img:nth-of-type(1) {
                    //     position: absolute;
                    //     top: 30%;
                    //     height: auto;
                    // }
                    // img:nth-of-type(2) {
                    //     position: absolute;
                    //     right: 15px;
                    //     top: 35%;
                    //     height: auto;
                    // }
                    
                    &:hover {
                        border-style: solid;
                        border-color: #412D7D;
                        background-color: #412D7D;
                        color: #FFF;
                        box-shadow: none; //0 16px 16px -9px hsla(0, 0%, 44%, .2);
                    }
                    &.disabled {
                        pointer-events: none;
                        color: #7e8488;
                    &.expanded {
                           padding: 12px 0;
                           display: flex;
                           justify-content: left;
                           align-items: center;
                        //    padding: unset;
    
                           img {
                               position: unset;
                           }
                           span {
                               position: unset;
    
                               .transfer-insuff-msg {
                                   margin: unset;
                               }
                           }
                    }
    
                        &>span {
                            pointer-events: none;
                            color: #7e8488;
                            display: block;
                            width: 80%;
                            top: 17px;
    
                            .transfer-insuff-msg {
                                left: 0;
                                width: 100%;
                                display: block;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                margin-top: 16px;
                            }
                        }
                        span {
                            // top: 45%;
                        }
                        img:last-of-type {
                            display: none;
                        }
                    }
                }
    
                // a:last-of-type, a:nth-of-type(3) {
                //     img:first-of-type {
                //         margin: 0 0 0 15px;
                //         top: 20px;
                //         width: 14%;
                //     }
                // }
                &:nth-of-type(1), &:nth-of-type(2){
                    .left-block{
                        .left-copy{
                            display: none;
                        }
                    }
                }
                // &:nth-of-type(3), &:nth-of-type(4){
                //     .right-block{
                //         .right-copy{
                //             display: none;
                //         }
                //     }
                // }

                // #transfer-cta-button-right-icon,#request-transfer-cta-button-right-icon{
                //     display: none;
                // }
                
                
            }
            
            
        }
        h3 {
            color: #7c848a;
            font-size: 18px;
            line-height: 16px;
            font-weight: 400;
            letter-spacing: 2px;
            text-decoration: none;
            margin-bottom: 15px;
            margin-top: 0;;
        }
        h2 {
            color: #000;
            font-size: 24px;
            line-height: 1.4;
            font-weight: 600;
            margin-bottom: 15px;
            margin-top: 0;
        }
        h4 {
            color: #000;
            font-size: 18px;
            line-height: 1.5;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 40px;
        }
        a {
            max-width: 365px;
            border: 1px solid #D4D4D0;
            border-radius: 3px;
            text-decoration: none;
            padding: 15px 0;
            display: flex;
            align-items: space-between;
            flex-direction: row;
            justify-content: space-between;
            box-shadow: 1px 1px -1px #cacaca;

            // img:nth-of-type(1) {
            //     width: 35px;
            //     margin: 0 18px;
            // }
            // img:nth-of-type(2) {
            //     width: 14px;
            //     margin-left: 30px;
            // }
        }

        p:nth-of-type(1) {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;
            margin-top: 40px;
        }
        p:nth-of-type(2) {
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-top: -10px;
        }
        .marriott-content {
            margin-top: 55px;

            h5 {
                color: #333;
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 10px;
            }
            p {
                margin: 10px 0 10px;
                font-size: 16px;
                font-weight: 300;
                line-height: 1.5;
            }
            a {
                display: inline-block;
                color: #039;
                border: none;
                padding: 0;

                span {
                    display: inline-block;
                    vertical-align: middle;
                }
                img {
                    width: 10px;
                    vertical-align: middle;
                    height: auto;
                }
            }
        }
    }
}

.transfer {
    clear: both;
    margin-bottom: 0px;
}

.pointshound {
    clear: both;
    //display: none;
}

.requestTransfer {
    clear: both;
    //display: none;
}
#earn{
    display: none;
}