
.home {
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0) 22%, white 90%),url('../assets/United-MPLP-hero.jpg');
    // background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 90%;
    height: 32vw;
    max-height: 600px;
        min-height: 450px;
    position: relative;
    .location-label{
        color: white;
        width: 300px;
        // background-color: red;
        text-align: right;
        position: absolute;
        bottom: 2%;
        right: 2%;
        text-shadow: 1px 1px 4px #000;
        
    }
    .black {
        display: none;
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        right: 0;
        background-color: #000;
        opacity: 0;
        z-index: 10;
        transition: 0.2s all ease-in-out;
    }
    .home-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #01246b, #2172ba);
        z-index: 0;
        opacity: 0.9;
    }
    .home-banner {
        position: absolute;
        width: 100%;
        display: block;
        z-index: -300;
        max-height: 100%;
        height: auto;
        .video-overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, #01246b, #2172ba);
            z-index:5;
        }
    }   
}
.home-content {
    max-width: 1100px;
    width: 90%;
    margin: 0 auto;
    z-index: 20;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    line-height: 1.3;
    position: relative;
    // max-width: 1150px;
    // min-width: 25%;
    // width: 25%;
    // margin: 50px auto 170px auto;
    // clear: both;
    // position: relative;
    .home-content_text, .home-mobile-content_text{
        display: none;
    }
    h1 {
        font-weight: 800;
        font-size: 27px;
        line-height: 1.3;
        margin-bottom: 20px;
        width: 47%;
    }
    p {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
        text-shadow: 2px 2px 4px #fff;
        width: 42%;
    }

    &.active {
        opacity: 1;
        transform: scaleX(1) scaleY(1) scaleZ(1);
        transition: opacity 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    }

    .home-subheader_desktop {
        
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
        margin-bottom: 60px;
        
    }
    .home-subheader_mobile {
        display: none;
    }

    
    
      
}