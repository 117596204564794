.home {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 22%, white 90%), url("../assets/United-MPLP-hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 90%;
  height: 32vw;
  max-height: 600px;
  min-height: 450px;
  position: relative;
}
.home .location-label {
  color: white;
  width: 300px;
  text-align: right;
  position: absolute;
  bottom: 2%;
  right: 2%;
  text-shadow: 1px 1px 4px #000;
}
.home .black {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #000;
  opacity: 0;
  z-index: 10;
  transition: 0.2s all ease-in-out;
}
.home .home-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #01246b, #2172ba);
  z-index: 0;
  opacity: 0.9;
}
.home .home-banner {
  position: absolute;
  width: 100%;
  display: block;
  z-index: -300;
  max-height: 100%;
  height: auto;
}
.home .home-banner .video-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #01246b, #2172ba);
  z-index: 5;
}

.home-content {
  max-width: 1100px;
  width: 90%;
  margin: 0 auto;
  z-index: 20;
  font-family: "Open Sans", sans-serif;
  color: #000;
  line-height: 1.3;
  position: relative;
}
.home-content .home-content_text, .home-content .home-mobile-content_text {
  display: none;
}
.home-content h1 {
  font-weight: 800;
  font-size: 27px;
  line-height: 1.3;
  margin-bottom: 20px;
  width: 47%;
}
.home-content p {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  text-shadow: 2px 2px 4px #fff;
  width: 42%;
}
.home-content.active {
  opacity: 1;
  transform: scaleX(1) scaleY(1) scaleZ(1);
  transition: opacity 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.home-content .home-subheader_desktop {
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 60px;
}
.home-content .home-subheader_mobile {
  display: none;
}

.main-item_quote .slideUp {
  display: none;
}

.buy {
  margin: 0 auto 70px auto;
}
.buy .main-item_image {
  background-image: url(../assets/photography/desk-5.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.transfer .main-item_image {
  background-image: url(../assets/photography/desk-7.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.reinstate .main-item_image {
  background-image: url(../assets/photography/desk-9.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 470px;
}

.pointshound .main-item_image {
  background-image: url(../assets/photography/pointshound_miles.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 480px;
}

.main {
  background-color: #fff;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.main-item {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto 170px auto;
  clear: both;
  padding-top: 40px;
  margin-bottom: 50px;
  position: relative;
  background-color: #fff;
  display: flex;
}
.main-item.buy {
  flex-direction: row-reverse;
}
.main-item.buy .main-item_content {
  margin-left: 2%;
  margin-right: 25px;
}
.main-item.buy .main-item_content .main-item_buttons .cta-button a {
  width: 100%;
  max-width: 285px;
}
.main-item.buy .main-item_content .main-item_buttons .cta-button a span {
  margin: 0;
}
@media (max-width: 780px) {
  .main-item.buy .main-item_content .main-item_buttons .cta-button a span {
    margin: 0 auto;
  }
}
@media (max-width: 560px) {
  .main-item.buy .main-item_content .main-item_buttons .cta-button a span {
    width: 100% !important;
    margin: 10px !important;
  }
}
.main-item.transfer {
  flex-direction: row;
}
.main-item.transfer .main-item_content {
  margin-left: 10%;
}
.main-item.transfer .main-item_content .main-item_buttons .cta-button a {
  width: 100%;
  max-width: 325px;
}
.main-item.transfer .main-item_content .main-item_buttons .cta-button a span {
  margin: 0 15px;
}
@media (max-width: 950px) {
  .main-item.transfer .main-item_content .main-item_buttons .cta-button a span {
    margin: 0 auto;
  }
}
@media (max-width: 860px) {
  .main-item.transfer .main-item_content .main-item_buttons .cta-button a span {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .main-item.transfer .main-item_content .main-item_buttons .cta-button a span {
    font-size: 14px;
  }
}
@media (max-width: 560px) {
  .main-item.transfer .main-item_content .main-item_buttons .cta-button a span {
    width: 100% !important;
    margin: 10px !important;
  }
}
.main-item .main-item_image {
  width: 50%;
  max-width: 515px;
  height: 485px;
  position: relative;
  border-radius: 6px;
}
.main-item .main-item_image .main-item_quote {
  background-color: #fff;
  border: 1px solid black;
  width: 220px;
  padding: 8px 15px;
  font-size: 14px;
  text-align: center;
  border: 0px solid #cecece;
  border-radius: 3px;
  box-shadow: 0 0 6px 0 #ccc;
  position: absolute;
  transition: all 250ms;
}
.main-item .main-item_image .main-item_quote p:nth-of-type(2) {
  font-style: italic;
  font-weight: 300;
}
.main-item .main-item_image .main-item_icon {
  display: none;
}
.main-item .main-item_content {
  width: 50%;
}
.main-item .main-item_content .main-item_buttons .cta-button {
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  align-items: center;
  margin-bottom: 16px;
}
.main-item .main-item_content .main-item_buttons .cta-button:nth-child(even) a {
  color: #745AC4;
  background-color: #FFFFFF;
  border: 2px solid #6244BB;
  box-shadow: none;
}
.main-item .main-item_content .main-item_buttons .cta-button:nth-child(even) a:hover {
  border-style: solid;
  color: #745AC4;
  background-color: #F7F7F7;
  border-color: #6244BB;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
}
.main-item .main-item_content .main-item_buttons .cta-button .left-block {
  width: 10%;
  text-transform: uppercase;
  color: #6244BB;
  font-size: 13px;
}
.main-item .main-item_content .main-item_buttons .cta-button .right-block {
  width: 25%;
  text-transform: uppercase;
  color: #6244BB;
}
.main-item .main-item_content .main-item_buttons .cta-button .right-block .right-copy {
  font-size: 13px;
  font-weight: bold;
}
.main-item .main-item_content .main-item_buttons .cta-button .tool-tip {
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #006080 2px solid;
  border-radius: 50%;
  margin: 0px 15px auto 4px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 840px) {
  .main-item .main-item_content .main-item_buttons .cta-button .tool-tip {
    width: 28px;
  }
}
@media (max-width: 768px) {
  .main-item .main-item_content .main-item_buttons .cta-button .tool-tip {
    width: 25px;
  }
}
@media (max-width: 560px) {
  .main-item .main-item_content .main-item_buttons .cta-button .tool-tip {
    width: 29px;
  }
}
.main-item .main-item_content .main-item_buttons .cta-button .tool-tip .tool-tip-logo {
  font-size: 12px;
  color: #000;
  font-weight: 500;
}
.main-item .main-item_content .main-item_buttons .cta-button .tool-tip .tool-tip-content {
  border: #ccc 1px solid;
  text-align: center;
  font-size: 10px;
  width: 250px;
  border-radius: 5%;
  position: absolute;
  top: 0%;
  left: 20px;
  visibility: hidden;
  z-index: 1;
  background-color: #fff;
}
.main-item .main-item_content .main-item_buttons .cta-button .tool-tip:hover .tool-tip-content {
  visibility: visible;
}
.main-item .main-item_content .main-item_buttons .cta-button a {
  position: relative;
  text-align: center;
  align-items: center;
  display: block;
  font-size: 14px;
  color: #FFF;
  background-color: #6244BB;
  border: 2px solid #745AC4;
  font-weight: 600px;
  box-shadow: -1px 1px 1px 0 #666;
  transition: box-shadow 342ms ease, all 500ms ease;
  border-radius: 4px;
  height: 52px;
  cursor: pointer;
}
.main-item .main-item_content .main-item_buttons .cta-button a .left-mobile-block {
  display: none;
}
.main-item .main-item_content .main-item_buttons .cta-button a .right-mobile-block {
  display: none;
}
.main-item .main-item_content .main-item_buttons .cta-button a span .sale-text {
  left: initial;
  top: 4%;
  right: -50%;
  color: #ffbd00;
}
.main-item .main-item_content .main-item_buttons .cta-button a:hover {
  border-style: solid;
  border-color: #412D7D;
  background-color: #412D7D;
  color: #FFF;
  box-shadow: none;
}
.main-item .main-item_content .main-item_buttons .cta-button a.disabled {
  pointer-events: none;
  color: #7e8488;
}
.main-item .main-item_content .main-item_buttons .cta-button a.disabled.expanded {
  padding: 12px 0;
  display: flex;
  justify-content: left;
  align-items: center;
}
.main-item .main-item_content .main-item_buttons .cta-button a.disabled.expanded img {
  position: unset;
}
.main-item .main-item_content .main-item_buttons .cta-button a.disabled.expanded span {
  position: unset;
}
.main-item .main-item_content .main-item_buttons .cta-button a.disabled.expanded span .transfer-insuff-msg {
  margin: unset;
}
.main-item .main-item_content .main-item_buttons .cta-button a.disabled > span {
  pointer-events: none;
  color: #7e8488;
  display: block;
  width: 80%;
  top: 17px;
}
.main-item .main-item_content .main-item_buttons .cta-button a.disabled > span .transfer-insuff-msg {
  left: 0;
  width: 100%;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 16px;
}
.main-item .main-item_content .main-item_buttons .cta-button a.disabled img:last-of-type {
  display: none;
}
.main-item .main-item_content .main-item_buttons .cta-button:nth-of-type(1) .left-block .left-copy, .main-item .main-item_content .main-item_buttons .cta-button:nth-of-type(2) .left-block .left-copy {
  display: none;
}
.main-item .main-item_content h3 {
  color: #7c848a;
  font-size: 18px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 2px;
  text-decoration: none;
  margin-bottom: 15px;
  margin-top: 0;
}
.main-item .main-item_content h2 {
  color: #000;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 0;
}
.main-item .main-item_content h4 {
  color: #000;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 40px;
}
.main-item .main-item_content a {
  max-width: 365px;
  border: 1px solid #D4D4D0;
  border-radius: 3px;
  text-decoration: none;
  padding: 15px 0;
  display: flex;
  align-items: space-between;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 1px 1px -1px #cacaca;
}
.main-item .main-item_content p:nth-of-type(1) {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 40px;
}
.main-item .main-item_content p:nth-of-type(2) {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: -10px;
}
.main-item .main-item_content .marriott-content {
  margin-top: 55px;
}
.main-item .main-item_content .marriott-content h5 {
  color: #333;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}
.main-item .main-item_content .marriott-content p {
  margin: 10px 0 10px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}
.main-item .main-item_content .marriott-content a {
  display: inline-block;
  color: #039;
  border: none;
  padding: 0;
}
.main-item .main-item_content .marriott-content a span {
  display: inline-block;
  vertical-align: middle;
}
.main-item .main-item_content .marriott-content a img {
  width: 10px;
  vertical-align: middle;
  height: auto;
}

.transfer {
  clear: both;
  margin-bottom: 0px;
}

.pointshound {
  clear: both;
}

.requestTransfer {
  clear: both;
}

#earn {
  display: none;
}

.earn {
  clear: both;
  padding: 25px 0;
  text-align: center;
  background-image: linear-gradient(180deg, #f5f5f5, #f5f5f5);
}
.earn h2 {
  color: #000;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 600;
  margin-top: 40px;
}

@media (max-width: 1024px) {
  .earn .no-slider {
    display: none;
  }
}

.earn-container {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  clear: both;
  padding: 40px 0;
}
.earn-container a {
  margin: 0 auto;
  text-align: left;
  border: 1px solid #c6c6c6;
  border-radius: 3px;
  transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  text-decoration: none;
  background-color: #fff;
  width: 100%;
  display: inline-block;
}
@media (min-width: 1024px) {
  .earn-container a {
    width: 23.5%;
  }
}
.earn-container a:hover {
  transform: translate(0px, -12px);
  box-shadow: 0 10px 17px -9px hsla(0, 0%, 44%, 0.2);
}
.earn-container a .earn-item_top {
  border-bottom: 1px solid #c6c6c6;
  background-position: 50% 50%, 0px 0px;
  background-size: 100%, auto;
  background-repeat: no-repeat;
  padding-bottom: 65%;
}
.earn-container a .earn-item_bottom {
  padding: 18px;
  background-color: white;
}
.earn-container a .earn-item_bottom h3 {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  margin-top: 0;
}
.earn-container a .earn-item_bottom p {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: black;
}
.earn-container .groupon .earn-item_top {
  background-image: url(../assets/photography/groupon.jpg), linear-gradient(180deg, #fff, #fff);
  background-size: 85%, auto;
}
.earn-container .mileageplus .earn-item_top {
  background-image: url(../assets/photography/MPLP_Mobile_Cards_0009_giftCards.jpg), linear-gradient(180deg, #fff, #fff);
}
.earn-container .rewardplus .earn-item_top {
  background-image: url(../assets/photography/marriott_bonvoy.png), linear-gradient(180deg, #fff, #fff);
  background-size: 85%, auto;
}
.earn-container .pointshound .earn-item_top {
  background-image: url(../assets/photography/desk-8.jpg), linear-gradient(180deg, #fff, #fff);
}
.earn-container .giftcards .earn-item_top {
  background-image: url(http://place-hold.it/350x150), linear-gradient(180deg, #fff, #fff);
}
.earn-container .earn-redeem .earn-item_top {
  background-image: url(../assets/photography/mplpCards_MPX.jpg), linear-gradient(180deg, #fff, #fff);
}
.earn-container .awardaccelerator .earn-item_top {
  background-image: url(../assets/photography/MPLP_Mobile_Cards_0002_awardAccelerator.jpg), linear-gradient(180deg, #fff, #fff);
}
.earn-container .placeholder .earn-item_top {
  background-image: url(http://place-hold.it/350x150), linear-gradient(180deg, #fff, #fff);
}
.earn-container .merchandise-awards .earn-item_top {
  background-image: url(../assets/photography/mplpCards_Merch.jpg), linear-gradient(180deg, #fff, #fff);
}
.earn-container .united-cruises .earn-item_top {
  background-image: url(../assets/photography/mplpCards_Cruises.jpg), linear-gradient(180deg, #fff, #fff);
}
.earn-container .united-explorer .earn-item_top {
  background-image: url(../assets/photography/mplpCards_ChaseCard.jpg), linear-gradient(180deg, #fff, #fff);
}
.earn-container .hotel-awards .earn-item_top {
  background-image: url(../assets/photography/mplpCards_HotelAwards.jpg), linear-gradient(180deg, #fff, #fff);
}
.earn-container .premier-accelerator .earn-item_top {
  background-image: url(../assets/photography/mplpCards_PremierAccelerator.jpg), linear-gradient(180deg, #fff, #fff);
  background-size: 104%;
}

@media (min-width: 1024px) {
  .slider {
    padding: 0;
  }
}

.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  height: unset;
}
.slick-track .slick-slide a {
  height: 100%;
}
.slick-track .slick-slide > div {
  height: 100%;
}

.slick-initialized .slick-slide, .slick-initialized .slick-active, .slick-initialized .slick-current {
  padding: 0px 9px;
}

.slick-dots {
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .slick-list {
    overflow: initial;
  }
}
.footer {
  padding: 40px 0;
  border-top: 2px none #039;
  clear: both;
}
.footer .home-content_text {
  line-height: 30px;
  width: 95%;
  max-width: 1150px;
  margin: auto;
}
.footer .home-content_text p {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
}
.footer .home-content_text a {
  display: inline-block;
  margin-left: 4px;
  text-decoration: underline;
  color: #006080;
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}
.footer .home-content_text a::after {
  content: ">";
  margin-left: 5px;
  text-decoration: none;
}
.footer .home-content_text a:hover {
  color: #0033a0;
  border-bottom: 1px solid #fff;
}

.footer-container {
  width: 95%;
  max-width: 1150px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding: 20px 0;
}
.footer-container .footer-item {
  display: inline-block;
  text-align: left;
  width: 23%;
  margin: 0 2% 0 auto;
  vertical-align: text-top;
}
.footer-container .footer-item h4 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 10px 0;
}
.footer-container .footer-item a {
  display: block;
  text-decoration: none;
  color: #000;
  vertical-align: text-top;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 8px;
  cursor: pointer;
}
.footer-container .footer-item a span {
  border-bottom: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}
.footer-container .footer-item a span:hover {
  border-bottom: 1px solid #000;
}

.home .showNav {
  top: 0;
  transition: all 0.7s ease-in-out;
}

.hamburger-nav {
  display: none;
}

#hamburger-nav .fixed-nav_container .fixed-nav_links a span br {
  display: none;
}
#hamburger-nav .fixed-nav_container .nav-language-container {
  width: 85%;
  padding-top: 24px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
  margin: 20px auto;
}
#hamburger-nav .fixed-nav_container .nav-language-container .lanSelect {
  display: block;
  padding: 0;
}
#hamburger-nav .fixed-nav_container .nav-language-container .lanSelect .lang-button {
  cursor: pointer;
  list-style: none;
  padding: 12px 0 12px 16px;
  font-size: 14px;
  border: 0;
  display: block;
  background-color: transparent;
  color: white;
}
#hamburger-nav .fixed-nav_container .nav-language-container .lanSelect .lang-button:hover {
  font-weight: 700;
}
#hamburger-nav .fixed-nav_container .nav-language-container .lanSelect .lang-button-active {
  cursor: pointer;
  list-style: none;
  padding: 12px 0 12px 16px;
  font-size: 14px;
  border: 0;
  display: block;
  background-color: transparent;
  color: white;
  font-weight: 700;
}
#hamburger-nav .fixed-nav_container .nav-language-container .lanSelect-show {
  display: block;
}

.fixed-nav {
  position: fixed;
  display: none;
  top: -100%;
  left: 0;
  color: #000;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  transition: all 0.7s ease-in-out;
}
.fixed-nav .fixed-nav_container {
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
  z-index: 20;
}
.fixed-nav .fixed-nav_container .fixed-nav_logo {
  padding: 0;
}
.fixed-nav .fixed-nav_container .fixed-nav_logo img {
  width: 160px;
  margin-top: 12px;
  height: auto;
}
.fixed-nav .fixed-nav_container .hamburger {
  display: none;
}
.fixed-nav .fixed-nav_container .fixed-nav_links {
  display: inline-block;
  float: right;
  margin: 0;
}
.fixed-nav .fixed-nav_container .fixed-nav_links a {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 25px 0;
  margin-right: 30px;
  cursor: pointer;
  border-bottom: 4px solid #0c2c40;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #000;
}
.fixed-nav .fixed-nav_container .fixed-nav_links a:nth-last-of-type(1) {
  margin: 0;
}
.fixed-nav .fixed-nav_container .fixed-nav_links a.active, .fixed-nav .fixed-nav_container .fixed-nav_links a:hover {
  border-bottom: 4px solid #69b3e7;
}
.fixed-nav .fixed-nav_container .fixed-nav_links a span br {
  display: none;
}
.fixed-nav .fixed-nav_container .nav-language-container {
  display: none;
}
.fixed-nav .fixed-member_container {
  display: none;
}

.home-nav {
  padding: 15px 0 0px 0;
  margin-bottom: 100px;
}
.home-nav .home-nav-container {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  position: relative;
}
.home-nav .home-nav-container img {
  float: left;
  max-width: 160px;
  height: auto;
}
.home-nav .home-nav-container .hamburger {
  display: none;
  position: absolute;
  width: 22px;
  padding: 2px;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
.home-nav .home-nav-container .hamburger img {
  width: 100%;
}
.home-nav .home-nav-container .nav-language-container {
  float: right;
  position: relative;
  padding-top: 12px;
}
.home-nav .home-nav-container .nav-language-container .nav-language-menu {
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  color: #000;
  cursor: pointer;
}
.home-nav .home-nav-container .nav-language-container .nav-language-menu span {
  padding-right: 6px;
}
.home-nav .home-nav-container .nav-language-container .nav-language-menu .flip-horizontal-top {
  position: absolute;
  width: 12px;
  top: 22px;
  animation: 0.4s ease-in-out;
}
.home-nav .home-nav-container .nav-language-container .flip-horizontal-bottom {
  -webkit-animation: flip-horizontal-bottom 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-horizontal-bottom 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  position: absolute;
  width: 12px;
  top: 22px;
}
@-webkit-keyframes flip-horizontal-top {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
}
@keyframes flip-horizontal-top {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
}
@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
  }
}
.home-nav .home-nav-container .nav-language-container select {
  float: right;
}
.home-nav .home-nav-container .nav-language-container .lanSelect {
  display: none;
  position: absolute;
  padding: 10px 0;
  z-index: 30;
}
.home-nav .home-nav-container .nav-language-container .lanSelect .lang-button {
  padding: 7px 70px 7px 19px;
  width: 140px;
  border: 0;
  background-color: #fff;
  color: black;
  list-style: none;
  font-size: 14px;
  cursor: pointer;
}
.home-nav .home-nav-container .nav-language-container .lanSelect .lang-button:hover, .home-nav .home-nav-container .nav-language-container .lanSelect .lang-button:focus {
  background-color: #0c2c40;
  color: #fff;
  transition: ease-in-out 0.1s;
}
.home-nav .home-nav-container .nav-language-container .lanSelect .lang-button-active {
  padding: 7px 70px 7px 19px;
  width: 140px;
  border: 0;
  list-style: none;
  font-size: 14px;
  cursor: pointer;
  background-color: #0c2c40;
  color: #fff;
  transition: ease-in-out 0.1s;
}
.home-nav .home-nav-container .nav-language-container .lanSelect-show {
  display: block;
}

.scroll {
  background: linear-gradient(180deg, rgba(179, 188, 200, 0.33), #fff);
}

.home::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 65%, white 90%);
}
.home .scroll-nav {
  display: none;
}
.scroll .scroll-nav {
  display: none;
}

.scroll-nav {
  display: block;
  text-align: center;
  position: relative;
}
.scroll-nav .scroll-item {
  transform: translateY(-80px);
  background: white;
  display: inline-block;
  text-decoration: none;
  margin: 0 0.7%;
  width: 190px;
  height: 180px;
  border-radius: 5px;
  border: 1px solid #D4D4D0;
  vertical-align: bottom;
  transition: all 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  opacity: 0.74;
  transform: translate(0px, 250px);
}
.scroll-nav .scroll-item.active {
  opacity: 1;
  transform: translateX(0px) translateY(-80px) translateZ(0px);
  transition: opacity 200ms ease 0s, transform 700ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.scroll-nav .scroll-item img {
  display: block;
  height: 47px;
  width: 47px;
  margin: 20% auto 10% auto;
}
.scroll-nav .scroll-item span {
  font-size: 16px;
  color: rgb(124, 132, 138);
  text-decoration: none;
  width: 20px;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  transition: all 0.5s ease-in-out;
}
.scroll-nav .scroll-item:first-of-type.delay {
  transition: opacity 200ms ease 0s, transform 700ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.scroll-nav .scroll-item:nth-of-type(2).delay {
  transition: opacity 200ms ease 0s, transform 900ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.scroll-nav .scroll-item:nth-of-type(3).delay {
  transition: opacity 200ms ease 0s, transform 1100ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.scroll-nav .scroll-item:nth-of-type(4).delay {
  transition: opacity 200ms ease 0s, transform 1300ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.scroll-nav .scroll-item:nth-of-type(5).delay {
  transition: opacity 200ms ease 0s, transform 1500ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}
.scroll-nav .scroll-item:first-of-type.active:hover, .scroll-nav .scroll-item:nth-of-type(2).active:hover, .scroll-nav .scroll-item:nth-of-type(3).active:hover, .scroll-nav .scroll-item:nth-of-type(4).active:hover, .scroll-nav .scroll-item:nth-of-type(5).active:hover {
  box-shadow: 0 18px 17px -9px hsla(0, 0%, 44%, 0.2);
  transform: translate(0px, -89px);
  transition: all 500ms ease;
}
.scroll-nav .scroll-item:first-of-type.active:hover span, .scroll-nav .scroll-item:nth-of-type(2).active:hover span, .scroll-nav .scroll-item:nth-of-type(3).active:hover span, .scroll-nav .scroll-item:nth-of-type(4).active:hover span, .scroll-nav .scroll-item:nth-of-type(5).active:hover span {
  color: #006080;
}

@media (max-width: 1400px) {
  .main-item .main-item_content .main-item_buttons .cta-button .tool-tip .transfer-cta-button-tool-tip-content, .main-item .main-item_content .main-item_buttons .cta-button .tool-tip .request-transfer-cta-button-tool-tip-content {
    left: auto;
    right: 5px;
    top: 14px;
    transform: translateY(-60px);
  }
}
@media (max-width: 1200px) {
  .home-nav {
    margin: 0px 20px 100px 35px;
  }
  .home-content {
    width: auto;
    margin-left: 60px;
  }
  .home-content h1 {
    width: 500px;
  }
  .home-content p {
    width: 450px;
  }
  .main-item .main-item_image {
    margin: 0px 10px;
  }
  .main-item.transfer .main-item_content {
    margin-left: 8%;
  }
}
@media (max-width: 1140px) {
  .main-item .main-item_image {
    margin: 0px 10px 0 0px;
  }
  .main-item.transfer .main-item_content {
    margin-left: 11vh;
  }
}
@media (max-width: 1024px) {
  .home .black-show {
    display: block;
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
  }
  .home .hamburger-show {
    right: 0;
    transition: all 0.2s ease-in-out;
  }
  .home-nav {
    margin: 0px 20px 100px 0px;
  }
  .home .home-nav .home-nav-container .nav-language-container {
    display: none;
  }
  .home .home-content {
    max-width: 500px;
    text-align: center;
    margin: 0px auto 200px;
  }
  .home .home-content h1 {
    width: 100%;
    font-size: 23px;
    margin-bottom: 10px;
  }
  .home .home-content p {
    width: 100%;
  }
  .hamburger-nav {
    display: block;
    position: fixed;
    top: 0;
    right: -340px;
    left: unset;
    width: 340px;
    height: 100vh;
    z-index: 900;
    background-color: #0C2340;
    color: #fff;
    transition: all 0.2s ease-in-out;
  }
  .hamburger-nav .hamburger-member_container {
    width: 100%;
    margin: 0 auto;
    padding: 28px 0 40px 0;
    display: block;
    background: linear-gradient(180deg, #01246b, #01246b);
  }
  .hamburger-nav .hamburger-member_container .member-greeting {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.35);
    margin: 0 auto 18px auto;
    width: 85%;
  }
  .hamburger-nav .hamburger-member_container .member-greeting p {
    display: inline-block;
    margin: 0;
    padding-bottom: 8px;
  }
  .hamburger-nav .hamburger-member_container .member-greeting span {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .hamburger-nav .hamburger-member_container .member-balance {
    margin: 0 auto 18px auto;
    width: 85%;
  }
  .hamburger-nav .hamburger-member_container .member-balance p {
    display: inline-block;
    color: #ffbd00;
    margin: 0;
  }
  .hamburger-nav .hamburger-member_container .member-balance span {
    display: inline-block;
    margin: 0;
  }
  .hamburger-nav .hamburger-member_container .member-number {
    width: 85%;
    margin: 0 auto;
  }
  .hamburger-nav .hamburger-member_container .member-number p {
    display: inline-block;
    margin: 0;
  }
  .hamburger-nav .hamburger-member_container .member-number span {
    display: inline-block;
    margin: 0 0 4px 0;
  }
  .hamburger-nav .fixed-nav_container .fixed-nav_logo {
    display: none;
    padding: 12px 12px;
  }
  .hamburger-nav .fixed-nav_container .fixed-nav_logo img {
    width: 120px;
    margin: 12px 12px;
  }
  .hamburger-nav .fixed-nav_container .fixed-nav_links {
    float: none;
    width: 85%;
    margin: 0 auto;
    cursor: pointer;
  }
  .hamburger-nav .fixed-nav_container .fixed-nav_links a {
    display: block;
    float: none;
    text-align: left;
    padding: 16px 0;
    width: 85%;
    color: #fff;
    text-decoration: none;
  }
  .hamburger-nav .fixed-nav_container .fixed-nav_links a:nth-last-of-type(1) {
    width: 85%;
  }
  .hamburger-nav .fixed-nav_container .fixed-nav_links a span {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 16px;
  }
  .hamburger-nav .fixed-nav_container .fixed-nav_links .fixed-nav_member {
    display: block;
    background: linear-gradient(180deg, #01246b, #01246b);
  }
  .hamburger-nav .fixed-nav_container .fixed-nav_links .fixedNavItems a {
    display: block;
    float: none;
    text-align: left;
    padding: 20px 0 0 20px;
  }
  .hamburger-nav .fixed-nav_container .fixed-nav_links .fixedNavItems a span {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 16px;
  }
  .fixed-nav {
    position: fixed;
    display: none;
    top: -100%;
    left: 0;
    color: #fff;
    width: 100%;
    z-index: 100;
    transition: all 0.4s ease-in-out;
  }
  .fixed-nav .fixed-nav_container {
    max-width: 1200px;
    margin: 22px auto;
    z-index: 20;
    position: relative;
  }
  .fixed-nav .fixed-nav_container .fixed-nav_logo img {
    width: 120px;
    margin: 14px 12px 8px 12px;
  }
  .fixed-nav .fixed-nav_container .hamburger {
    display: inline-block;
    position: absolute;
    width: 25px;
    padding: 2px;
    top: 17px;
    right: 10px;
  }
  .fixed-nav .fixed-nav_container .hamburger img {
    width: 100%;
    margin-top: 12px;
  }
  .fixed-nav .fixed-nav_container .fixed-nav_links {
    display: none;
    float: right;
    margin: 0;
  }
  .fixed-nav .fixed-nav_container .fixed-nav_links a {
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.5px;
    padding: 25px 0;
    margin-right: 30px;
    cursor: pointer;
    border-bottom: 3px solid #039;
    transition: all 0.2s ease-in-out;
  }
  .fixed-nav .fixed-nav_container .fixed-nav_links a:nth-last-of-type(1) {
    margin: 0;
  }
  .fixed-nav .fixed-nav_container .fixed-nav_links a:hover {
    border-bottom: 3px solid #ffbd00;
  }
  .fixed-nav .fixed-nav_container .nav-language-container {
    display: none;
  }
  .home-nav .home-nav-container .hamburger {
    display: block;
  }
  .scroll .scroll-nav .scroll-item {
    width: 134px;
    height: 137px;
  }
  .scroll .scroll-nav .scroll-item img {
    width: 35px;
    height: 25px;
  }
  .scroll .scroll-nav .scroll-item span {
    font-size: 13px;
  }
  .main-item {
    margin: 0 auto 0px auto;
    width: 100%;
  }
  .main-item.transfer .main-item_content {
    margin-left: 0%;
    padding-left: 7%;
  }
  .main-item .main-item_image {
    display: inline-block;
    height: 450px;
    width: 50%;
    margin: 0px 10px;
  }
  .main-item .main-item_image .main-item_quote {
    display: none;
  }
  .main-item .main-item_content {
    display: inline-block;
    width: calc(60% - 40px);
    margin: 0;
  }
  .main-item .main-item_content h2 {
    font-size: 26px;
  }
  .main-item .main-item_content h3 {
    font-size: 16px;
  }
  .main-item .main-item_content h4 {
    margin-bottom: 25px;
  }
  .main-item .main-item_content .marriott-content {
    margin-top: 15px;
  }
  .main-item .main-item_content .main-item_buttons .cta-button .left-block {
    width: 14%;
    text-align: center;
  }
  .main-item .main-item_content .main-item_buttons a {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .home .home-bg {
    background: initial;
  }
  .home .hamburger-nav {
    right: -240px;
    width: 240px;
  }
  .home .hamburger-show {
    right: 0;
    transition: all 0.2s ease-in-out;
  }
  .home .fixed-nav .fixed-nav_container {
    margin: 0 auto;
  }
  .home {
    min-height: 460px;
  }
  .home .scroll_mobile {
    display: none;
  }
  .home .scroll_mobile .scroll-nav {
    display: block;
  }
  .home .scroll_mobile .scroll-nav h1 {
    margin-bottom: 0;
    width: auto;
  }
  .home .scroll_mobile .scroll-nav .scroll-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72%;
    height: 55px;
    margin: 20px auto;
    background-color: #fff;
    color: #fff;
    position: relative;
    transform: translateX(0px) translateY(0px) translateZ(0px);
    transform: scale(0.5, 0.5);
    opacity: 0;
  }
  .home .scroll_mobile .scroll-nav .scroll-item.active {
    opacity: 1;
    transform: scaleX(1) scaleY(1) scaleZ(1);
    transition: 500ms all ease-in;
  }
  .home .scroll_mobile .scroll-nav .scroll-item img {
    display: inline-block;
    width: 25px;
    height: auto;
    margin: 0 auto;
    position: absolute;
    left: 14px;
    top: 14px;
  }
  .home .scroll_mobile .scroll-nav .scroll-item span {
    display: inline-block;
    font-weight: 400;
    color: #006080;
    width: 70%;
    text-align: left;
    vertical-align: middle;
    margin-left: 35px;
    font-size: 14px;
    letter-spacing: 0;
  }
  .home .scroll_mobile .scroll-nav .scroll-item span br {
    display: none;
  }
  .scroll .scroll-nav {
    display: none;
  }
  .home-nav {
    margin-bottom: 15px;
    padding: 40px 0 50px;
  }
  .home-nav .home-nav-container {
    width: 90%;
  }
  .home-nav .home-nav-container a img {
    width: 120px;
  }
  .home-nav .home-nav-container .hamburger {
    display: inline-block;
  }
  .home-nav .home-nav-container .hamburger img {
    width: 25px;
    margin-top: 12px;
  }
  .home .home-content {
    margin: 0px auto 100px auto;
  }
  .home .home-content p {
    font-size: 16px;
  }
  .home .home-content h1 {
    font-size: 21px;
    line-height: 1.3;
    font-weight: 600;
  }
  .home .home-content h2 {
    margin-bottom: 40px;
  }
  .home .home-content .home-subheader_desktop {
    display: none;
  }
  .home .home-content .home-subheader_desktop h2 {
    font-size: 16px;
    line-height: 24px;
  }
  .home .home-content .home-content_text, .home .home-content .home-subheader_mobile {
    display: block;
    text-decoration: none;
    transform: scale(0.5, 0.5);
    opacity: 0;
  }
  .home .home-content .home-content_text span, .home .home-content .home-subheader_mobile span {
    color: #fff;
    line-height: 24px;
    font-size: 16px;
    text-decoration: none;
  }
  .home .home-content .home-content_text a, .home .home-content .home-subheader_mobile a {
    border-bottom: 1px solid #fff;
    line-height: 12px;
  }
  .home .home-content .home-content_text.active, .home .home-content .home-subheader_mobile.active {
    opacity: 1;
    transform: scaleX(1) scaleY(1) scaleZ(1);
    transition: 300ms all ease-in;
  }
  .earn h2 {
    width: 95%;
  }
  .earn .earn-container a {
    width: 95%;
  }
  .footer-block .home-content_text {
    margin-left: 30px;
  }
  .footer-container {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer-container .footer-item {
    display: block;
    width: 90%;
    padding: 14px 0;
    margin: 0 auto 10px auto;
    border-bottom: 1px solid #c6c6c6;
  }
  .footer-container .footer-item:nth-last-of-type(1) {
    padding: 14px 0 0 0;
    margin: 0 auto;
    border-bottom: none;
  }
  .main .main-item {
    margin: 40px 0 30px 0;
    padding: 0;
    width: 100%;
    display: block;
  }
  .main .main-item.transfer .main-item_content {
    padding-left: 0%;
  }
  .main .main-item.transfer .main-item_image {
    display: block !important;
  }
  .main .main-item .main-item_image {
    display: none;
    float: none;
    width: auto;
    border-radius: 0%;
    max-width: 100%;
    margin: 0px;
    height: 450px;
    position: relative;
    background-size: 100%;
    background-position: center 85%;
  }
  .main .main-item .main-item_image .main-item_quote {
    display: none;
  }
  .main .main-item .main-item_image .main-item_icon {
    display: none;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    bottom: -42px;
    left: 0px;
  }
  .main .main-item .main-item_image .main-item_icon img {
    width: 40px;
    margin: 23px;
  }
  .main .main-item .main-item_content {
    display: block;
    width: auto;
    margin: 4% 10% 0% 10%;
  }
  .main .main-item .main-item_content h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .main .main-item .main-item_content h2 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .main .main-item .main-item_content h4 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .main .main-item .main-item_content .main-item_buttons .cta-button .tool-tip .tool-tip-content {
    left: auto;
    right: 5px;
    top: 14px;
    transform: translateY(-60px);
  }
}
@media (max-width: 560px) {
  #multiply-cta-button img.left-mobile-block, #subscribe-cta-button img.left-mobile-block {
    display: none;
  }
  #buy-cta-button div.left-mobile-block, #gift-cta-button div.left-mobile-block, #transfer-cta-button div.left-mobile-block, #request-transfer-cta-button div.left-mobile-block {
    display: none;
  }
  .home {
    background-size: cover;
    background-position: 50% 70%;
  }
  .home .location-label {
    display: none;
  }
  .home .home-nav {
    padding: 20px 0 50px;
  }
  .home .home-content {
    margin: 0px 20px auto;
  }
  .home .home-content h1 {
    margin-bottom: 10px;
    font-size: 17px;
    text-shadow: none;
  }
  .home .home-content p {
    font-size: 16px;
    text-shadow: none;
  }
  .home .home-content .home-mobile-content_text {
    max-width: 550px;
    transform: none;
    color: #000;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home .home-content .home-mobile-content_text p {
    width: auto;
    line-height: 1.5;
    margin-right: 5px;
  }
  .home .home-content .home-mobile-content_text p span {
    color: #000;
    text-shadow: 2px 2px 6px #fff;
  }
  .home .home-content .home-mobile-content_text a {
    border-bottom: none;
    text-decoration: none;
    line-height: 1.5;
    text-shadow: 2px 2px 6px #fff;
  }
  .home .home-content .home-mobile-content_text a span {
    color: #000;
    font-weight: 600;
  }
  .App .main .main-item.buy .main-item_image {
    display: none;
  }
  .App .main .main-item .main-item_image {
    background-size: cover;
    height: 300px;
    width: 95%;
    margin: 0 auto;
  }
  .App .main .main-item .main-item_buttons .cta-button .left-block {
    display: none;
  }
  .App .main .main-item .main-item_buttons .cta-button a {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 440px;
  }
  .App .main .main-item .main-item_buttons .cta-button a span {
    width: 70%;
    font-size: 13px;
    margin: 0px;
    text-align: center;
  }
  .App .main .main-item .main-item_buttons .cta-button a img.left-mobile-block {
    width: 10%;
    margin-left: 20px;
    height: 24px;
  }
  .App .main .main-item .main-item_buttons .cta-button a .left-mobile-block {
    display: none;
  }
  .App .main .main-item .main-item_buttons .cta-button a .right-mobile-block {
    display: none;
    margin-right: 20px;
  }
  .App .main .main-item .main-item_buttons .cta-button a div.left-mobile-block {
    margin-left: 20px;
    font-size: 15px;
  }
  .footer-block .home-content_text {
    margin-left: 30px;
    display: none;
  }
}
@media (max-width: 390px) {
  .main .main-item .main-item_content {
    margin: 4% 5% 0% 5%;
  }
  .main-item .main-item_content .main-item_buttons .cta-button .right-block .right-copy {
    font-size: 11px;
  }
}
@media (max-width: 420px) {
  .home {
    min-height: 420px;
  }
  .home .home-content h1 {
    font-size: 16px;
    text-shadow: none;
  }
  .home .home-content p {
    font-size: 12px;
  }
  .home .home-content .home-mobile-content_text {
    font-size: 12px;
    display: block;
  }
}
@media (max-width: 950px) {
  #transfer-cta-button-right-icon, #request-transfer-cta-button-right-icon {
    width: 25%;
  }
}
@media only screen and (max-width: 770px) and (min-width: 768px) {
  #request-transfer-cta-button {
    padding: none;
  }
}
* {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  margin: 0;
}
*:focus {
  outline: -webkit-focus-ring-color auto 5px;
}
* a img {
  border: none;
}

.hide {
  display: none;
}

.united-login-detect-iframe {
  display: none;
}

.iframe-con {
  position: relative;
  z-index: 11;
}
.iframe-con .iframeBG {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.iframe-con .close-x {
  position: fixed;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  z-index: 999;
  width: 494px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: right;
  top: calc(50% - 285px);
}
.iframe-con .loading-gif {
  position: fixed;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 494px;
  height: 505px;
}
.iframe-con .loading-gif img {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.iframe-con .loading-gif.hide {
  display: none;
}

.loginModal {
  opacity: 0;
  position: fixed;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 494px;
  height: 505px;
  padding: 10px;
  z-index: 999;
}
.loginModal.show {
  opacity: 1;
  transition: 250ms all ease-in;
}
.loginModal.hide {
  display: none;
}

@media (max-width: 560px) {
  .iframe-con .close-x {
    width: 90%;
    top: 1%;
  }
  .loginModal {
    width: 90%;
    top: 32%;
  }
}
/*
Baseline Buy/Gift Tile Image Reference */
.buy .main-item_image {
  background-image: url(https://points-ebgt.s3.amazonaws.com/united/promotions/2025/Feb/MPLP.png);
  background-position: bottom right;
}

/*
// Only use this in case an image does not fit properly
// If sharp border corners are an issue, try using background-size: cover
// If the above method doesn't work, edit the max-width to a smaller size and add '!important' (perhaps for min $screen-mobile-lrg only
// If the above method is not solving the issue and the image simply needs horizontal centering, try background-position: right; OR background-position: left;
.buy .main-item_image {
 	background-image: url(https://points-ebgt.s3.amazonaws.com/united/promotions/2022/MPLP_Dec-05-2022-600x600.jpg);
    background-size: contain;
    @media(max-width: $screen-mobile-lrg) {
        background-size: contain !important;
    }
    @media(max-width: $screen-mobile-sml) {
        background-size: contain !important;
    }  
}*/
.transfer .main-item_image {
  background-image: url(../assets/photography/desk-7.jpg);
}

