@import 'partials/_variables.scss';
@import 'partials/_home.scss';
@import 'partials/_main.scss';
@import 'partials/_earn.scss';
@import 'partials/_footer.scss';
@import 'partials/_navs.scss';
@import 'partials/_mediaqueries.scss';

* {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    margin: 0;

    &:focus {
        outline: -webkit-focus-ring-color auto 5px;
    }
    a img {border:none}
}
.hide {
    display: none;
}

.united-login-detect-iframe {
    display: none;
}
.iframe-con {
    position: relative;
    z-index: 11;

    .iframeBG{
        position: fixed;
        background-color: rgba(0,0,0,0.8);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .close-x {
        position: fixed;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        z-index: 999;
        width: 494px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: right;
        top: calc(50% - 285px);
    }
    .loading-gif {
        position: fixed;
        background-color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 494px;
        height: 505px;

        img {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.hide {
            display: none;
        }
    }
}
.loginModal {
    opacity: 0;
    position: fixed;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 494px;
    height: 505px;
    padding: 10px;
    z-index: 999;

    &.show {
        opacity: 1;
        transition: 250ms all ease-in;
    }
    &.hide {
        display: none;
    }
}
@media(max-width: $screen-mobile-sml){
    .iframe-con {
        .close-x {
            width: 90%;
            top: 1%;
        }
    }
    .loginModal {
        width: 90%;
        top: 32%;
    }
}


/*
Baseline Buy/Gift Tile Image Reference */
 //.buy .main-item_image {
    //revert to base hero image
    //background-image: url(../assets/photography/desk-5.jpg);
    //background-position: bottom right;

 //}

// Promo

 .buy .main-item_image {
   background-image: url(https://points-ebgt.s3.amazonaws.com/united/promotions/2025/Feb/MPLP.png);
   background-position: bottom right;
 }


/*
// Only use this in case an image does not fit properly
// If sharp border corners are an issue, try using background-size: cover
// If the above method doesn't work, edit the max-width to a smaller size and add '!important' (perhaps for min $screen-mobile-lrg only
// If the above method is not solving the issue and the image simply needs horizontal centering, try background-position: right; OR background-position: left;
.buy .main-item_image {
 	background-image: url(https://points-ebgt.s3.amazonaws.com/united/promotions/2022/MPLP_Dec-05-2022-600x600.jpg);
    background-size: contain;
    @media(max-width: $screen-mobile-lrg) {
        background-size: contain !important;
    }
    @media(max-width: $screen-mobile-sml) {
        background-size: contain !important;
    }  
}*/


// Baseline Transfer Tile Image Reference
.transfer .main-item_image {
    // Revert image to base hero-image
    background-image: url(../assets/photography/desk-7.jpg);
    
    // Promo hero-image.
    //background-image: url(https://points-ebgt.s3.amazonaws.com/united/promotions/2024-Promos/September/2624_MP_TransferMiles_Sept_Step1_Mobile_576x640.jpg);
    //background-position: center 25% !important;
    //height: 450px !important;
}
