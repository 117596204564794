
.earn {
    clear: both;
    padding: 25px 0;
    text-align: center;
    background-image: linear-gradient(180deg, #f5f5f5, #f5f5f5);

    h2 {
        color: #000;
        font-size: 24px;
        line-height: 1.4;
        font-weight: 600;
        margin-top: 40px;
    }
}

.earn .no-slider {
    @media(max-width: 1024px) {
        display: none;
    }
}

.earn-container {
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // overflow: auto;
    clear: both;
    padding: 40px 0;

    a {
        // width: 23.5%;
        margin: 0 auto;
        text-align: left;
        border: 1px solid #c6c6c6;
        border-radius: 3px;
        transition: all 500ms cubic-bezier(.645, .045, .355, 1);
        text-decoration: none;
        background-color: #fff;
        // height: 100%;
        // max-height: 400px;
        width: 100%;
        display: inline-block;

        @media(min-width: 1024px) {
            width: 23.5%;
        }


        &:hover {
            transform: translate(0px, -12px);
            box-shadow: 0 10px 17px -9px hsla(0, 0%, 44%, .2);
        }

        .earn-item_top {
           border-bottom: 1px solid #c6c6c6;
        //    height: 175px;
           background-position: 50% 50%, 0px 0px;
           background-size: 100%, auto;
           background-repeat: no-repeat;
           padding-bottom: 65%;

            // img {
            //     // width: 100%;
            //     // height: 175px;
            //     // display: block;
            // }
        }
        .earn-item_bottom {
            padding: 18px;
            background-color: white;
            // height: 100%;

            h3 {
                font-size: 16px;
                line-height: 1.2;
                font-weight: 600;
                color: #000;
                margin-bottom: 10px;
                margin-top: 0;
            }

            p {
                font-size: 14px;
                line-height: 1.5;
                font-weight: 400;
                color: black;
            }
        }
    }
    .groupon {
        .earn-item_top {
            background-image: url(../assets/photography/groupon.jpg), linear-gradient(180deg, #fff, #fff);
            background-size: 85%, auto;
        }
    }
    .mileageplus {
        .earn-item_top {
            background-image: url(../assets/photography/MPLP_Mobile_Cards_0009_giftCards.jpg), linear-gradient(180deg, #fff, #fff);
        }
    }
    .rewardplus {
        .earn-item_top {
            background-image: url(../assets/photography/marriott_bonvoy.png), linear-gradient(180deg, #fff, #fff);
            background-size: 85%, auto;
        }
    }
    .pointshound {
        .earn-item_top {
            background-image: url(../assets/photography/desk-8.jpg), linear-gradient(180deg, #fff, #fff);
        }
    }
    .giftcards {
        .earn-item_top {
            background-image: url(http://place-hold.it/350x150), linear-gradient(180deg, #fff, #fff);
        }
    }
    .earn-redeem {
        .earn-item_top {
            background-image: url(../assets/photography/mplpCards_MPX.jpg), linear-gradient(180deg, #fff, #fff);
        }
    }
    .awardaccelerator {
        .earn-item_top {
            background-image: url(../assets/photography/MPLP_Mobile_Cards_0002_awardAccelerator.jpg), linear-gradient(180deg, #fff, #fff);
        }
    }
    .placeholder {
        .earn-item_top {
            background-image: url(http://place-hold.it/350x150), linear-gradient(180deg, #fff, #fff);
        }
    }
    .merchandise-awards {
        .earn-item_top {
            background-image: url(../assets/photography/mplpCards_Merch.jpg), linear-gradient(180deg, #fff, #fff);
        }
    }
    .united-cruises {
        .earn-item_top {
            background-image: url(../assets/photography/mplpCards_Cruises.jpg), linear-gradient(180deg, #fff, #fff);
        }
    }
    .united-explorer {
        .earn-item_top {
            background-image: url(../assets/photography/mplpCards_ChaseCard.jpg), linear-gradient(180deg, #fff, #fff);
        }
    }
    .hotel-awards {
        .earn-item_top {
            background-image: url(../assets/photography/mplpCards_HotelAwards.jpg), linear-gradient(180deg, #fff, #fff);
        }
    }
     .premier-accelerator {
        .earn-item_top {
            background-image: url(../assets/photography/mplpCards_PremierAccelerator.jpg), linear-gradient(180deg, #fff, #fff);
            background-size: 104%;
        }
    }
}
//Slick Carousel

.slider {
    @media(min-width: 1024px) {
        padding: 0;
    }
}

.slick-track {
    display: flex;

    .slick-slide {
        // flex: 1;
        // width: 50%;
        height: unset;
        // height: inherit;
        a {
            height: 100%;
        }


        >div {
            height: 100%;
        }



    }
}

.slick-initialized {
    .slick-slide, .slick-active, .slick-current {
        padding: 0px 9px;
        // height: unset;

        // &>div {
        //     height: 100%;
        // }
    }
}
.slick-dots {
    margin-bottom: 20px;
}
@media(min-width: $screen-tablet-lrg){
    .slick-list {
        overflow: initial;
    }
}
